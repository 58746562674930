.navigatorWrap {
    :global {
        .ant-menu-horizontal {
            .ant-menu-item {
                height: 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: #fff !important;
                font-size: 16px;
                font-weight: 600;
            }
        }
    }
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
}

.navigatorBg {
    background: linear-gradient(180deg, #001b34 0%, rgba(0, 0, 0, 0) 100%) !important;
}

.navigator {
    display: flex;
    position: sticky;
    align-items: center;
    width: 100%;
    z-index: 2;
    border-bottom: 1px solid white;
}

.logo {
    padding: 0 10px;
}

.menuStyle {
    .ant-menu-item {
        margin-left: 16px;
    }
}
