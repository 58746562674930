
.loader {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #101010;
}

#wave {
	height: 70px;
	width: 70px;
	fill: #fff;
}

@for $i from 1 through 9 {
	#Line_#{$i} {
		animation: pulse 1s infinite;
		animation-delay: $i * .15s;
	}
}

@keyframes pulse {
	0% {
		transform: scaleY(1);
		transform-origin: 50% 50%;
	}
	
	50% {
		transform: scaleY(.7);
		transform-origin: 50% 50%;
	}
	
	100% {
		transform: scaleY(1);
		transform-origin: 50% 50%;
	}
}